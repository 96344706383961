import '../styles/newsletter.scss'

import React, { useState } from 'react'

import Layout from '../layouts/Layout'

const NewsletterPage = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = e => {
    const encode = data => {
      return Object.keys(data)
        .map(
          key =>
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(data[key])
        )
        .join('&')
    }
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'newsletter',
        name: name,
        email: email,
      }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch(error => alert(error))

    e.preventDefault()
  }

  if (submitted) {
    return (
      <Layout id="newsletter-page">
        <section>
          <h1>Registration successful!</h1>
          <p>Keep an eye on your inbox for the latest from INW.</p>
        </section>
      </Layout>
    )
  }

  return (
    <Layout id="newsletter-page">
      <section>
        <h1>Sign up for the INW Newsletter</h1>
        <form
          id="newsletter-form"
          name="newsletter"
          data-netlify="true"
          method="post"
          netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input
            type="hidden"
            name="form-name"
            value="newsletter"
            aria-hidden
          />
          <input type="hidden" name="bot-field" aria-hidden />
          <div>
            <label htmlFor="name">Your Name</label>
            <input
              name="name"
              id="name"
              aria-label="Your Name"
              type="text"
              required
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              name="email"
              id="email"
              type="email"
              aria-label="Email Address"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <input
            name="submit"
            type="submit"
            value="Sign up"
            aria-label="Sign up"
            className="button"
            onSubmit={handleSubmit}
          />
        </form>
      </section>
    </Layout>
  )
}

export default NewsletterPage
